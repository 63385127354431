

































import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'

import Vue from 'vue'
import Component from 'vue-class-component'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'

@Component({
  name: 'cove-footer',
  components: {
    VueCookieAcceptDecline
  }
})
export default class MinFooterComponent extends Vue {
  private year = new Date().getFullYear()

  private cookieClickedAccept() {
  //   (window as any).fbq('dataProcessingOptions', [])
  //  ;(window as any).fbq('trackCustom', 'AcceptedCookies', { accepted: 'true' })
  }
}
